require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

import '../stylesheets/application'
import 'bootstrap/dist/js/bootstrap';
import 'bootstrap-datepicker/dist/js/bootstrap-datepicker';
import * as Chart from 'chart.js';
import Swal, {update} from 'sweetalert2';
import flatpickr from 'flatpickr';

let research_log_callbacks = [];
let evaluator_disabled;
function activate_research_log_fields()
{
    let no_errors = !$('#errors_present').length;

    //Courses
    function collapse_course_fields(event)
    {
        event.preventDefault();

        let course_field = $(this).closest('.course_field');

        let period = $(course_field).find('.period-field option:selected').text();
        let name = $(course_field).find('.name-field').val();
        let format = (course_field).find('.format-field option:selected').text();
        let crn = $(course_field).find('.crn-field').val();
        let students = $(course_field).find('.students-field').val();
        let campus = (course_field).find('.campus-field option:selected').text();
        let unique_id = new Date().getTime();

        let course_table_body = $(`.course_table_body`);

        $(course_field).attr('unique_id', unique_id);
        $(course_table_body).append(`<tr class="course_row"><td>${period}</td><td>${name}</td><td>${format}</td><td>${crn}</td><td>${students}</td><td>${campus}</td><td><a href="#" class="expand_course_option" unique_id="${unique_id}">Editar</a></td></tr>`);
        $(course_field).hide();

        refresh_course_table_options();
    }
    $('.course_collapse_button').on('click', collapse_course_fields);
    if(no_errors) {
        $('.course_collapse_button').click();
    }

    function refresh_course_table_options()
    {
        $('.expand_course_option').unbind('click');
        $('.expand_course_option').bind('click', function(event){
            event.preventDefault();

            let course_row = $(this).closest('.course_row');
            let unique_id = $(this).attr('unique_id');
            let course_field = $(`.course_field[unique_id='${unique_id}']`);

            $(course_row).remove();
            $(course_field).show();
        });
    }

    research_log_callbacks.push( () => {
        $('.course_collapse_button').unbind('click', collapse_course_fields);
        $('.course_collapse_button').on('click', collapse_course_fields);
    });

    //Acquisitions
    function collapse_acquisition_fields(event)
    {
        event.preventDefault();

        let acquisition_field = $(this).closest('.acquisition_field');

        let category = $(acquisition_field).find('.category-field option:selected').text();
        let description = $(acquisition_field).find('.description-field').val();
        let inventory_code = $(acquisition_field).find('.inventory-code-field').val();
        let unique_id = new Date().getTime();

        let acquisition_table_body = $(`.acquisition_table_body`);

        $(acquisition_field).attr('unique_id', unique_id);
        $(acquisition_table_body).append(`<tr class="acquisition_row"><td>${category}</td><td>${description}</td><td>${inventory_code}</td><td><a href="#" class="expand_acquisition_option" unique_id="${unique_id}">Editar</a></td></tr>`);
        $(acquisition_field).hide();

        refresh_acquisition_table_options();
    }
    $('.acquisition_collapse_button').on('click', collapse_acquisition_fields);
    if(no_errors) {
        $('.acquisition_collapse_button').click();
    }

    function refresh_acquisition_table_options()
    {
        $('.expand_acquisition_option').unbind('click');
        $('.expand_acquisition_option').bind('click', function(event){
            event.preventDefault();

            let acquisition_row = $(this).closest('.acquisition_row');
            let unique_id = $(this).attr('unique_id');
            let acquisition_field = $(`.acquisition_field[unique_id='${unique_id}']`);

            $(acquisition_row).remove();
            $(acquisition_field).show();
        });
    }

    research_log_callbacks.push( () => {
        $('.acquisition_collapse_button').unbind('click', collapse_acquisition_fields);
        $('.acquisition_collapse_button').on('click', collapse_acquisition_fields);
    });

    //Students
    function collapse_student_fields(event)
    {
        event.preventDefault();

        let student_field = $(this).closest('.student_field');

        let period = $(student_field).find('.period-field option:selected').text();
        let name = $(student_field).find('.name-field').val();
        let code = $(student_field).find('.code-field').val();
        let hours = $(student_field).find('.hours-field').val();
        let unique_id = new Date().getTime();

        let student_table_body = $(`.student_table_body`);

        $(student_field).attr('unique_id', unique_id);
        $(student_table_body).append(`<tr class="student_row"><td>${period}</td><td>${name}</td><td>${code}</td><td>${hours}</td><td><a href="#" class="expand_student_option" unique_id="${unique_id}">Editar</a></td></tr>`);
        $(student_field).hide();

        refresh_student_table_options();
    }
    $('.student_collapse_button').on('click', collapse_student_fields);
    if(no_errors) {
        $('.student_collapse_button').click();
    }

    function refresh_student_table_options()
    {
        $('.expand_student_option').unbind('click');
        $('.expand_student_option').bind('click', function(event){
            event.preventDefault();

            let student_row = $(this).closest('.student_row');
            let unique_id = $(this).attr('unique_id');
            let student_field = $(`.student_field[unique_id='${unique_id}']`);

            $(student_row).remove();
            $(student_field).show();
        });
    }

    research_log_callbacks.push( () => {
        $('.student_collapse_button').unbind('click', collapse_student_fields);
        $('.student_collapse_button').on('click', collapse_student_fields);
    });

    //Variable
    function collapse_variable_fields(event)
    {
        event.preventDefault();

        let variable_field = $(this).closest('.variable_field');

        let variable_type = $(variable_field).find('.variable-field option:selected').text();
        let focus = (variable_field).find('.focus-field option:selected').text();
        let instrument = (variable_field).find('.instrument-field option:selected').text();
        let unique_id = new Date().getTime();

        let variable_table_body = $(`.variable_table_body`);

        $(variable_field).attr('unique_id', unique_id);
        $(variable_table_body).append(`<tr class="variable_row"><td>${variable_type}</td><td>${focus}</td><td>${instrument}</td><td><a href="#" class="expand_variable_option" unique_id="${unique_id}">Editar</a></td></tr>`);
        $(variable_field).hide();

        refresh_variable_table_options();
    }
    $('.variable_collapse_button').on('click', collapse_variable_fields);
    if(no_errors) {
        $('.variable_collapse_button').click();
    }

    function refresh_variable_table_options()
    {
        $('.expand_variable_option').unbind('click');
        $('.expand_variable_option').bind('click', function(event){
            event.preventDefault();

            let variable_row = $(this).closest('.variable_row');
            let unique_id = $(this).attr('unique_id');
            let variable_field = $(`.variable_field[unique_id='${unique_id}']`);

            $(variable_row).remove();
            $(variable_field).show();
        });
    }

    research_log_callbacks.push( () => {
        $('.variable_collapse_button').unbind('click', collapse_variable_fields);
        $('.variable_collapse_button').on('click', collapse_variable_fields);
    });

    function refresh_variable_field_visibility(event)
    {
        event.preventDefault();

        let variable_field = $(this).closest('.variable_field');

        let data_collection_valid = $(variable_field).find('.data-collection-valid-select option:selected').text();
        let data_collection_validation_group = $(variable_field).find('.data-collection-validation-group');

        if(data_collection_valid === 'No')
        {
            data_collection_validation_group.hide();
        }
        else
        {
            data_collection_validation_group.show();
        }
    }

    research_log_callbacks.push( () => {
        $('.data-collection-valid-select').unbind('change', refresh_variable_field_visibility);
        $('.data-collection-valid-select').on('change', refresh_variable_field_visibility);
    });

    function refresh_all_variable_fields_visiblity() {
        $('.data-collection-valid-select').each(function (index, element) {
            let variable_field = $(element).closest('.variable_field');

            let data_collection_valid = $(variable_field).find('.data-collection-valid-select option:selected').text();
            let data_collection_validation_group = $(variable_field).find('.data-collection-validation-group');

            if(data_collection_valid === 'No')
            {
                data_collection_validation_group.hide();
            }
            else
            {
                data_collection_validation_group.show();
            }
        });
    }
    refresh_all_variable_fields_visiblity();

    //Resultados
    function collapse_result_fields(event)
    {
        event.preventDefault();

        let result_field = $(this).closest('.result_field');

        let collection = $(result_field).find('.collection-field option:selected').text();
        let group = (result_field).find('.group-field option:selected').text();
        let analysis = (result_field).find('.analysis-field option:selected').text();
        let result = (result_field).find('.result-field option:selected').text();
        let unique_id = new Date().getTime();

        let result_table_body = $(`.result_table_body`);

        $(result_field).attr('unique_id', unique_id);
        $(result_table_body).append(`<tr class="result_row"><td>${collection}</td><td>${group}</td><td>${analysis}</td><td>${result}</td><td><a href="#" class="expand_result_option" unique_id="${unique_id}">Editar</a></td></tr>`);
        $(result_field).hide();

        refresh_result_table_options();
    }
    $('.result_collapse_button').on('click', collapse_result_fields);
    if(no_errors) {
        $('.result_collapse_button').click();
    }

    function refresh_result_table_options()
    {
        $('.expand_result_option').unbind('click');
        $('.expand_result_option').bind('click', function(event){
            event.preventDefault();

            let result_row = $(this).closest('.result_row');
            let unique_id = $(this).attr('unique_id');
            let result_field = $(`.result_field[unique_id='${unique_id}']`);

            $(result_row).remove();
            $(result_field).show();
        });
    }

    research_log_callbacks.push( () => {
        $('.result_collapse_button').unbind('click', collapse_result_fields);
        $('.result_collapse_button').on('click', collapse_result_fields);
    });

    //Producción Científica
    function collapse_scientific_fields(event)
    {
        event.preventDefault();

        let scientific_field = $(this).closest('.scientific_field');

        let typ = $(scientific_field).find('.type-field option:selected').text();
        let status = (scientific_field).find('.status-field option:selected').text();
        let media = $(scientific_field).find('.media-field').val();
        let unique_id = new Date().getTime();

        let scientific_table_body = $(`.scientific_table_body`);

        $(scientific_field).attr('unique_id', unique_id);
        $(scientific_table_body).append(`<tr class="scientific_row"><td>${typ}</td><td>${status}</td><td>${media}</td><td><a href="#" class="expand_scientific_option" unique_id="${unique_id}">Editar</a></td></tr>`);
        $(scientific_field).hide();

        refresh_scientific_table_options();
    }
    $('.scientific_collapse_button').on('click', collapse_scientific_fields);
    if(no_errors) {
        $('.scientific_collapse_button').click();
    }

    function refresh_scientific_table_options()
    {
        $('.expand_scientific_option').unbind('click');
        $('.expand_scientific_option').bind('click', function(event){
            event.preventDefault();

            let scientific_row = $(this).closest('.scientific_row');
            let unique_id = $(this).attr('unique_id');
            let scientific_field = $(`.scientific_field[unique_id='${unique_id}']`);

            $(scientific_row).remove();
            $(scientific_field).show();
        });
    }

    research_log_callbacks.push( () => {
        $('.scientific_collapse_button').unbind('click', collapse_scientific_fields);
        $('.scientific_collapse_button').on('click', collapse_scientific_fields);
    });   

    // Selector de Producción Científica
    function update_scientific_status_selector(element) {
        let fieldset = $(element).closest('.scientific_field');
        let option_text = fieldset.find('.scientific_status_selector option:selected').text();

        fieldset.find('.accepted-group').hide();
        fieldset.find('.published-group').hide();
        fieldset.find('.accepted-field').prop('disabled', true);
        fieldset.find('.published-field').prop('disabled', true);

        if(option_text === 'Enviado' || option_text === 'Aceptado' || option_text === 'Escribiendo' ){
            fieldset.find('.accepted-group').show();
            fieldset.find('.accepted-field').prop('disabled', false);
        }
        else if (option_text === 'Publicado en proceedings' || option_text === 'Publicado en revista'){
            fieldset.find('.accepted-group').show();
            fieldset.find('.accepted-field').prop('disabled', false);
            fieldset.find('.published-group').show();
            fieldset.find('.published-field').prop('disabled', false);
        }
    }
    function update_scientific_status_selector_fields(event) {
        update_scientific_status_selector(event.target)
    }
    $('.scientific_status_selector').on('change', update_scientific_status_selector_fields);

    function update_all_scientific_status_selectors() {
        $('.scientific_status_selector').each(function (index, element) {
            update_scientific_status_selector(element);
        });
    }
    update_all_scientific_status_selectors();

    research_log_callbacks.push( () => {
        $('.scientific_status_selector').unbind('change', update_scientific_status_selector_fields);
        $('.scientific_status_selector').on('change', update_scientific_status_selector_fields);
        update_all_scientific_status_selectors();
    });

    //Presencia en medios
    function collapse_media_fields(event)
    {
        event.preventDefault();

        let media_field = $(this).closest('.media_field');

        let typ = $(media_field).find('.type-field').val();
        let name = $(media_field).find('.name-field').val();
        let date = $(media_field).find('.date-field').val();
        let unique_id = new Date().getTime();

        let media_table_body = $(`.media_table_body`);

        $(media_field).attr('unique_id', unique_id);
        $(media_table_body).append(`<tr class="media_row"><td>${typ}</td><td>${name}</td><td>${date}</td><td><a href="#" class="expand_media_option" unique_id="${unique_id}">Editar</a></td></tr>`);
        $(media_field).hide();

        refresh_media_table_options();
    }
    $('.media_collapse_button').on('click', collapse_media_fields);
    if(no_errors) {
        $('.media_collapse_button').click();
    }

    function refresh_media_table_options()
    {
        $('.expand_media_option').unbind('click');
        $('.expand_media_option').bind('click', function(event){
            event.preventDefault();

            let media_row = $(this).closest('.media_row');
            let unique_id = $(this).attr('unique_id');
            let media_field = $(`.media_field[unique_id='${unique_id}']`);

            $(media_row).remove();
            $(media_field).show();
        });
    }

    research_log_callbacks.push( () => {
        $('.media_collapse_button').unbind('click', collapse_media_fields);
        $('.media_collapse_button').on('click', collapse_media_fields);
    });

    //Transferencias
    function collapse_transfer_fields(event)
    {
        event.preventDefault();

        let transfer_field = $(this).closest('.transfer_field');

        let typ = $(transfer_field).find('.type-field option:selected').text();
        let status = (transfer_field).find('.status-field option:selected').text();
        let unique_id = new Date().getTime();

        let transfer_table_body = $(`.transfer_table_body`);

        $(transfer_field).attr('unique_id', unique_id);
        $(transfer_table_body).append(`<tr class="transfer_row"><td>${typ}</td><td>${status}</td><td><a href="#" class="expand_transfer_option" unique_id="${unique_id}">Editar</a></td></tr>`);
        $(transfer_field).hide();

        refresh_transfer_table_options();
    }
    $('.transfer_collapse_button').on('click', collapse_transfer_fields);
    if(no_errors) {
        $('.transfer_collapse_button').click();
    }

    function refresh_transfer_table_options()
    {
        $('.expand_transfer_option').unbind('click');
        $('.expand_transfer_option').bind('click', function(event){
            event.preventDefault();

            let transfer_row = $(this).closest('.transfer_row');
            let unique_id = $(this).attr('unique_id');
            let transfer_field = $(`.transfer_field[unique_id='${unique_id}']`);

            $(transfer_row).remove();
            $(transfer_field).show();
        });
    }

    research_log_callbacks.push( () => {
        $('.transfer_collapse_button').unbind('click', collapse_transfer_fields);
        $('.transfer_collapse_button').on('click', collapse_transfer_fields);
    });   


    $('.protocol-accept-date-button').on('click', function(event){
        event.preventDefault();
        let today = new Date().toISOString().slice(0, 10)

        $('.protocol-accept-date-button').hide();
        $('.protocol-accepted-date-field').val(today);
        $('.protocol-accepted-date').text(' Aceptado el ' + today);
    });

    $('.protocol-accept-reset-button').on('click', function(event){
        event.preventDefault();

        $('.protocol-accepted-date-field').val(null);
        $('.protocol-accepted-date').text(' RESETEADO');
    });

    $('.previous-procedure-select').on('change', function(event){
        event.preventDefault();

        let previous_procedure = $('.previous-procedure-select option:selected').text();

        if(previous_procedure === 'Sí')
        {
            $('.previous-procedure-justification-group').show();
        }
        else
        {
            $('.previous-procedure-justification-group').hide();
        }
    });
    $('.previous-procedure-select').trigger('change');

    $('.experimental-protocol-revision-button').on('click', function(event)
    {
        event.preventDefault();

        $('.experimental-protocol-status-hidden-field').val(2);
        $('.research_log_form').submit();
    });

    $('.protocol-edit-approved').on('click', function(event){
        event.preventDefault();

        $('.experimental-protocol-status-hidden-field').val(1);
        $('.research_log_form').submit();
    });
}

$( document ).on('turbolinks:load', function() {

    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover();

    window.setTimeout(() => {
        window.setTimeout(() => {
            document.body.classList.remove('loading');
        }, 350);
    });

    $('.datepicker').datepicker({format: 'dd/mm/yyyy'});

    flatpickr('.date-selector', {enableTime: false, dateFormat: 'Y-m-d'});
    flatpickr('.date-time-selector', {enableTime: true, dateFormat: 'Y-m-d H:i', time_24hr: true});
    flatpickr('.time-selector', {noCalendar: true, enableTime: true, dateFormat: 'H:i', time_24hr: true});

    $('form').on('click', '.remove_fields', function(event)
    {
        $(this).closest('.nested-item').hide();
        $(this).closest('.nested-item').children('input[type=hidden]').val('1');

        event.preventDefault();
    });
    /*---------------------------------*/ 
    $('.add_evaluator').click(function (event) {
        $(".dynamic_form_evaluator").append('<div class="form_evaluator"><div class="form-row align-items-end"><div class="form-group col text-right"><label for="email">Correo del evaluador</label></div><div class="form-group col-md-8"><input type="text" class="form-control email-evaluator"></div><div class="form-group col"><a class="text-white btn btn-danger remove_form_evaluator"><i class="mdi mdi-trash-can-outline"></i></a></div></div></div>');
        // alert('Hooray!');
        event.preventDefault(); // Prevent link from following its href
    });
    $(".simple-dynamic-form").on("click",".remove_form_evaluator",function(e){
        $(this).parents('.form_evaluator').remove();
        evaluator_disabled= false;
        e.preventDefault(); 
    });
    $(".evaluator_button").click(evaluator_add);
    if(evaluator_disabled===true){
        $('.add_evaluator').attr('disabled','disabled');
    }else{
        $('.add_evaluator').removeAttr('disabled');
    }

    function evaluator_add(event){
        let data = [];
        let proposal =$(".proposal-eval").val();
        let evaluator_email;
        $(".email-evaluator").each((i,item)=>
        {
            let email=item.value;
            if (isNaN(email)){//&& 
                console.log('email no vacio');
                data.push({'email': item.value});
            }else{
                console.log(' no vacio');
            }
            if (i>31){
                evaluator_disabled= true;
                $(".error-evaluator").html('<p class="text-danger">Has excedido el límite de evaluadores, elimina alguno para poder agregar otro.</p>');
//         evaluator_entry.find('.evaluator_error').html('<p class="text-danger">Correo no encontrado. Recuerda que debe estar registrado en la plataforma.</p>');
            }
        });
        // data.push({proposal:proposal});
        console.log(proposal);
        console.log(data);
        $.ajax({
            url: '/configurations/evaluator',
            data: {evaluator: data,
                   proposal: proposal},
                   type: 'POST',
                   success: (data)=>{
                    if(data.ok) {
                        // console.log(data.message);
                        console.log('exito');
                        $("#load-modal").modal('show');
                        
                    location.reload();
                    }else if(data.error){
                        $(".error-evaluator").html('<p class="text-danger">'+data.message+'. </p>');
                        // console.log(data.message);
                        console.log('error');
                    }
                }
            });
    }

    /***************************** */
    $('.simple-dynamic-form').on('click', '.add_fields', function(event) {
        let time = new Date().getTime();
        let regexp = new RegExp($(this).data('id'), 'g');
        let nested_anchor = $(this).prev('.nested-anchor');

        if($(nested_anchor).length)
        {
            $(nested_anchor).append($(this).data('fields').replace(regexp, time));
        }
        else
        {
            $(this).before($(this).data('fields').replace(regexp, time));
        }

        $('[data-toggle="tooltip"]').tooltip();
        $('[data-toggle="popover"]').popover();
        if($('.professor-search-group').length)
        {
            $('.search-professor-button').unbind('click', professor_search_service);
            $('.search-professor-button').on('click', professor_search_service);
        }
        // if($('.evaluator-entry').length){
        //     $('.evaluator_button').unbind('click', evaluator_add);
        //     $('.evaluator_button').bind('click', evaluator_add);
        // }
         
        if($('.collaborator-entry').length)
        {
            $('.collaborator_search_button').unbind('click', collaborator_search_service);
            $('.collaborator_search_button').bind('click', collaborator_search_service);
            
            $('.external_collaborator_search_button').unbind('click', external_collaborator_search_service);
            $('.external_collaborator_search_button').bind('click', external_collaborator_search_service);
        }
        if($('.total_budget_span').length)
        {
            $('.budget-field').unbind('change', recalculate_budget);
            $('.budget-field').bind('change', recalculate_budget);

            $('.activity_collapse_button').unbind('click', collapse_activity_fields);
            $('.activity_collapse_button').bind('click', collapse_activity_fields);
        }

        if($('.research_log_form').length)
        {
            $('.rs_variable_selector').unbind('click', update_rs_variable_selector_fields);
            $('.rs_variable_selector').bind('click', update_rs_variable_selector_fields);
            update_all_rs_variable_selectors();

            $('.rs_data_collection_selector').unbind('click', update_rs_data_collection_fields);
            $('.rs_data_collection_selector').bind('click', update_rs_data_collection_fields);
            update_all_rs_data_collection_selectors();

            research_log_callbacks.forEach(function(func){
                func();
            });
        }

        $('.date-selector').not('.flatpickr-input').each(function (index, element) {
            flatpickr(element, {enableTime: false, dateFormat: 'Y-m-d'});
        });

        //$('.date-selector')
        event.preventDefault();
    });

    $('#delete-modal').on('show.bs.modal', function(event) {
        let modal = $(this);
        let button = $(event.relatedTarget);
        let id = button.data('id');
        let name = button.data('name');
        let controller_name = button.data('controller-name');
        let delete_button = modal.find('.delete-trigger');
        let message_container = modal.find('.modal-body .message');
        let cancel_button = modal.find('.cancel-button');

        message_container.html(`¿Estás seguro que deseas eliminar ${name}?`);

        delete_button.unbind('click');
        delete_button.on('click', function(event)
        {
            $.ajax({
                url: `/${controller_name}/${id}`,
                method: 'DELETE',
                success: function(data)
                {
                    message_container.html(data.message);
                    delete_button.hide();
                    cancel_button.html('Cerrar');
                }
            });
        });
    });

    $('#delete-modal').on('hidden.bs.modal', function(event) {
        location.reload();
    });

    $('.filter-by-generation-button').on('click', function(event){
        event.preventDefault();
        const selected_generation = $('.filter-by-generation-select option:selected').text();
        console.log(selected_generation);
        window.location.href = `http://${window.location.host}/proposal20s?generation=${selected_generation}`
    });

    //Research Log
    if($('.research_log_form').length)
    {
        activate_research_log_fields();
    }

    //Proposal
    let proposal_errors = !$('#proposal_errors_present').length;
    if(proposal_errors)
    {
        $('.expand_activity_option').click();
    }

    $('#administrator_search_button').on('click', (event) => {
        let email = $('#administrator_search_field').val();

        $.ajax({
            url: '/professors/search',
            data: {email: email},
            success: (data) => {
                if(data.error) {
                    $('#administrator_search_error').html('<p class="text-danger">Profesor no encontrado. Recuerda que debe estar registrado en la plataforma.</p>');
                }
                else {
                    if (data.contract === 'planta'){
                    $('#administrator_search_error').html('<label style="font-weight: bolder;" class="font-16">Administrador(a) del proyecto.&nbsp</label><label class="brief-text">'+data.professor.name+' '+data.professor.last_name+'. </label><spam class="text-muted">'+data.professor.code+'. '+data.school+'. Campus '+data.campus+'.</spam>');
                    $('#administrator_id').val(data.professor.id);
                    }else{
                        $('#administrator_search_error').html('<p class="text-danger">La persona que administra los recursos debe tener un contrato de planta, intenta con otro correo electrónico.</p>');
                    }
                    }
            }
        });
    });
    

    function collaborator_search_service(event) {
        let collaborator_entry = $(this).closest('.collaborator-entry');
        let email = collaborator_entry.find('.collaborator_search_field').val();
        if (!email.includes('tec.mx')){
            collaborator_entry.find('.collaborator_search_error').html('<p class="text-danger">Debe registrar un participante con correo electrónico institucional.</p>');
        }else{
        $.ajax({
            url: '/professors/search',
            data: {email: email},
            success: (data) => {
                if(data.error) {
                    collaborator_entry.find('.collaborator_search_error').html('<p class="text-danger">Colaborador no encontrado. Recuerda que debe estar registrado en la plataforma.</p>');
                }
                else {
                    collaborator_entry.find('.collaborator_search_error')
                    .html('<label class="brief-text">'+data.professor.name+' '+data.professor.last_name+'. </label><spam class="text-muted">'+data.professor.code+'. '+data.school+'. Campus '+data.campus+'.</spam>');
                    collaborator_entry.find('.collaborator_id').val(data.professor.id);
                    //console.log("campus: "+data.campus);
                    //    collaborator_entry.find('.col_info').val('<label class="brief-text">'+data.professor.name+' '+data.professor.last_name+'. </label><spam class="text-muted">'+data.professor.code+'. '+data.school+'. Campus '+data.campus+'.</spam>');
                    
                    //collaborator_entry.find('.collaborator_last_name').val(data.professor.last_name);
                }
            }
        });
    }
    }
    $('.collaborator_search_button').on('click', collaborator_search_service);
    
    
    function external_collaborator_search_service(event) {
        let collaborator_entry = $(this).closest('.collaborator-entry');
        let email = collaborator_entry.find('.collaborator_search_field').val();
        if (email.includes('tec.mx')){
            collaborator_entry.find('.collaborator_search_error').html('<p class="text-danger">Debe registrar un participante con correo electrónico que no contenga “@tec.mx”.</p>');
        }else{
        $.ajax({
            url: '/professors/search',
            data: {email: email},
            success: (data) => {
                if(data.error) {
                    collaborator_entry.find('.collaborator_search_error').html('<p class="text-danger">Colaborador no encontrado. Recuerda que debe estar registrado en la plataforma.</p>');
                }
                else {
                    collaborator_entry.find('.collaborator_search_error')
                    .html('<label class="brief-text">'+data.professor.name+' '+data.professor.last_name+'. </label><spam class="text-muted">'+data.professor.code+'. '+data.external_institution+'</spam>');
                    collaborator_entry.find('.collaborator_id').val(data.professor.id);
                }
            }
        });
       }
    }

    function update_pedagogic_change_fields(event) {
        let option_text = $('.pedagogic_change_selector option:selected').text();

        if(option_text === 'Otro'){
            $('.pedagogic_change_other_fields').show();
        }
        else{
            $('.pedagogic_change_other_fields').hide();
        }
    }
    $('.pedagogic_change_selector').on('change', update_pedagogic_change_fields);
    update_pedagogic_change_fields();

    function update_education_technology_fields(event) {
        let option_text = $('.education_technology_selector option:selected').text();

        if(option_text === 'Otro'){
            $('.education_technology_other_fields').show();
            //console.log($('.education_technology_other_fields').html());
        }
        else{
            $('.education_technology_other_fields').hide();

            if(option_text === 'Ninguna'){
                let thematic_line_text = $('.thematic_line_selector option:selected').text();

                if(thematic_line_text === 'Tecnología para el aprendizaje'){
                    $('.education_technology_selector').val('');
                    alert('Escogiste "Tecnología para el aprendizaje" como línea temática. Debes elegir una opción diferente a Ninguna');
                }
            }
        }
    }
    $('.education_technology_selector').on('change', update_education_technology_fields);
    update_education_technology_fields();

    function update_previous_evidence_fields(event) {
        let option_text = $('.previous_evidence_selector option:selected').text();

        if(option_text === 'Esta idea ha participado en otras convocatorias o concursos'){
            $('.previous_evidence_other_fields').show();
        }
        else{
            $('.previous_evidence_other_fields').hide();
        }
    }
    $('.previous_evidence_selector').on('change', update_previous_evidence_fields);
    update_previous_evidence_fields();

    function update_variable_selector_fields(event) {
        let option_text = $('.variable_selector option:selected').text();

        $('.subvariable_fields').hide();
        $('.performance_variable_fields').hide();
        $('.performance_variable_fields').prop('disabled', true);
        $('.attention_variable_fields').hide();
        $('.attention_variable_fields').prop('disabled', true);
        $('.specify_variable_fields').hide();
        $('.specify_variable3_characters').hide();
        $('.specify_variable4_characters').hide();
        if(option_text === 'Conocimiento'){
            $('.subvariable_fields').show();
            $('.performance_variable_fields').show();
            $('.performance_variable_fields').prop('disabled', false);
        }
        else if (option_text === 'Actitud'){
            $('.subvariable_fields').show();
            $('.attention_variable_fields').show();
            $('.attention_variable_fields').prop('disabled', false);
        }
        else if (option_text !== 'Elegir variable'){
            $('.specify_variable_fields').show();
            if(option_text === 'Habilidad (transversal o disciplinar), 50 caracteres'){
                $('.specify_variable3_characters').show();
            }
            else if(option_text === 'Competencia (transversal o disciplinar), 200 caracteres'){
                $('.specify_variable4_characters').show();
            }
            else if(option_text === 'Valor, 50 caracteres'){
                $('.specify_variable3_characters').show();
            }
        }
    }
    $('.variable_selector').on('change', update_variable_selector_fields);
    update_variable_selector_fields();

    function update_variable_2_selector_fields(event) {
        let option_text = $('.variable_selector_2 option:selected').text();

        $('.subvariable_fields_2').hide();
        $('.performance_variable_fields_2').hide();
        $('.performance_variable_fields_2').prop('disabled', true);
        $('.attention_variable_fields_2').hide();
        $('.attention_variable_fields_2').prop('disabled', true);
        $('.specify_variable_fields_2').hide();

        if(option_text === 'Conocimiento'){
            $('.subvariable_fields_2').show();
            $('.performance_variable_fields_2').show();
            $('.performance_variable_fields_2').prop('disabled', false);
        }
        else if (option_text === 'Actitud'){
            $('.subvariable_fields_2').show();
            $('.attention_variable_fields_2').show();
            $('.attention_variable_fields_2').prop('disabled', false);
        }
        else if (option_text !== 'Elegir variable'){
            $('.specify_variable_fields_2').show();
            if(option_text === 'Habilidad (transversal o disciplinar), 50 caracteres'){
                $('.specify_variable_characters').html('<spam> de 50 caracteres</spam>');
            }
            else if(option_text === 'Competencia (transversal o disciplinar), 200 caracteres'){
                $('.specify_variable_characters').html('<spam> de 200 caracteres</spam>');
            }
            else if(option_text === 'Valor, 50 caracteres'){
                $('.specify_variable_characters').html('<spam> de 50 caracteres</spam>');
            }
        }
    }
    $('.variable_selector_2').on('change', update_variable_2_selector_fields);
    update_variable_2_selector_fields();

    function update_thematic_line_fields(event){
        let option_text = $('.thematic_line_selector option:selected').text();

        $('.tl_education_technology').hide();
        $('.tl_life_teachings').hide();
        $('.tl_alpha_digital').hide();
        $('.tl_learning_evaluation').hide();
        $('.justification_thematic_line_fields').hide();

        if(option_text !== 'Elegir línea temática'){
          if (option_text !== 'Ninguna'){
            $('.justification_thematic_line_fields').show();
          }
        }
    }
    $('.thematic_line_selector').on('change', update_thematic_line_fields);
    update_thematic_line_fields();

    function update_data_collection_fields(event) {
        let option_text = $('.data_collection_selector option:selected').text();

        if(option_text === 'Otro'){
            $('.data_collection_other_fields').show();
        }
        else{
            $('.data_collection_other_fields').hide();
        }
    }
    $('.data_collection_selector').on('change', update_data_collection_fields);
    update_data_collection_fields();

    $('.section1-collapse-button').on('click', (event) => {
        event.preventDefault();
        $('.section1').slideToggle();
    });

    $('.section2-collapse-button').on('click', (event) => {
        event.preventDefault();
        $('.section2').slideToggle();
    });

    $('.section3-collapse-button').on('click', (event) => {
        event.preventDefault();
        $('.section3').slideToggle();
    });

    $('.section4-collapse-button').on('click', (event) => {
        event.preventDefault();
        $('.section4').slideToggle();
    });

    $('.section5-collapse-button').on('click', (event) => {
        event.preventDefault();
        $('.section5').slideToggle();
    });

    //EVALUATION TOGGLE BUTTONS
    $('.ev-section1-collapse-button').on('click', (event) => {
        event.preventDefault();
        $('.section1').slideToggle();
    });

    $('.ev-section2-collapse-button').on('click', (event) => {
        event.preventDefault();
        $('.section2').slideToggle();
    });

    $('.ev-section3-collapse-button').on('click', (event) => {
        event.preventDefault();
        $('.section3').slideToggle();
    });

    $('.ev-section4-collapse-button').on('click', (event) => {
        event.preventDefault();
        $('.section4').slideToggle();
    });

    $('.ev-section5-collapse-button').on('click', (event) => {
        event.preventDefault();
        $('.section5').slideToggle();
    });

    function recalculate_budget(event){
        let total_budget = 0;
        let previous_budget_category = $('.budget_category_field').val();

        $('.budget-field').each(function(index, element){
           let amount = $(this).val();

           if(!isNaN(amount)){
               total_budget += Number(amount);
           }
        });

        total_budget = total_budget.toFixed(2);

        if(total_budget < 100000){
            $('#less_100000').show();
            $('#less_200000').hide();
            $('#more_200000').hide();
            $('.variable2_toggle').hide();
            $('.budget_category_field').val('lt_100000');
        } else if(total_budget >= 100000 && total_budget < 200000){
            $('#less_100000').hide();
            $('#less_200000').show();
            $('#more_200000').hide();
            $('.variable2_toggle').show();
            $('.budget_category_field').val('lt_200000');
        } else if(total_budget > 200000){
            $('#less_100000').hide();
            $('#less_200000').hide();
            $('#more_200000').show();
            $('.variable2_toggle').show();
            $('.budget_category_field').val('gt_200000');
        }

        if(previous_budget_category !== '' && previous_budget_category !== $('.budget_category_field').val()){
            $('.accept_commitment_field').prop('checked', false);

            Swal.fire({
                icon: 'warning',
                title: 'Importante',
                text: 'El presupuesto ha entrado en otra categoría. Los compromisos han cambiado y es necesario aceptarlos de nuevo.'
            })
        }

        $('.total_budget_span').html(total_budget);
    }
    $('.budget-field').on('change', recalculate_budget);
    recalculate_budget();

    function collapse_activity_fields(event){
        event.preventDefault();

        let activity_field = $(this).closest('.activity_field');
        let period = $(activity_field).find('.period_select option:selected').text();
        let activity_type = (activity_field).find('.activity_type_select option:selected').text();
        let category = (activity_field).find('.category_select option:selected').text();
        let detail = $(activity_field).find('.detail_field').val();
        let budget = $(activity_field).find('.budget-field').val();
        let quote = $(activity_field).find('.quote_url').attr('href');
        let quote_name = $(activity_field).find('.quote_url').text();
        let unique_id = new Date().getTime();
        
        if(category=== 'Elija una categoría'){
            category =" "
        }

        let table_body_section_class = '';
        if(period === 'Agosto-Diciembre 2020'){
            table_body_section_class = 'ad_2020';
        } else if(period === 'Enero-Junio 2021'){
            table_body_section_class = 'ej_2021';
        } else if(period === 'Verano 2021'){
            table_body_section_class = 'v_2021';
        } else {
            table_body_section_class = 'ad_2021';
        }

        let activity_table_body = $(`.${table_body_section_class}`);

        $(activity_field).attr('unique_id', unique_id);
        $(activity_table_body).append(`<tr class="activity_row"><td>${period}</td><td>${activity_type}</td><td>${category}</td><td>${detail}</td><td>${budget}</td><td><a href="${quote}">${quote_name}</a></td><td><a href="#" class="expand_activity_option" unique_id="${unique_id}">Editar</a></td></tr>`);
        $(activity_field).hide();

        refresh_activity_table_options();
    }
    $('.activity_collapse_button').on('click', collapse_activity_fields);
    $('.activity_collapse_button').click();

    function refresh_activity_table_options(){
        $('.expand_activity_option').unbind('click');
        $('.expand_activity_option').bind('click', function(event){
            event.preventDefault();

            let activity_row = $(this).closest('.activity_row');
            let unique_id = $(this).attr('unique_id');
            let activity_field = $(`.activity_field[unique_id='${unique_id}']`);

            $(activity_row).remove();
            $(activity_field).show();
        });
    }

    function answer_consensus_request(event, answer){
        let collaboration_card = $(event.target).closest('.collaboration-card');
        let controller = collaboration_card.attr('controller');
        let id = collaboration_card.attr('id');

        $.ajax({
            url: `/${controller}/consensus`,
            data: {id: id, answer: answer},
            success: (data) => {
                Swal.fire({
                    icon: answer ? 'success' : 'warning',
                    title: answer ? 'Éxito' : 'Importante',
                    text: answer ? 'Ha aceptado el consenso' : 'Ha declinado el consenso. Se ha retirado su colaboracíón de su propuesta'
                });
                collaboration_card.hide();
            }
        });
    }

    $('.accept-collaboration-button').on('click', function(event){
        answer_consensus_request(event, true);
    });

    $('.decline-collaboration-button').on('click', function(event){
        answer_consensus_request(event, false);
    });

    function updateCharCountedFields(target){
        let text_field = $(target);
        let char_count_span = text_field.closest('.form-group').find('.char_counter');
        let char_count = text_field.val().length;
        console.log(text_field.value);
        char_count_span.text(char_count)
    }
    $('.char_counted').on('change paste keyup', function(event){
        updateCharCountedFields(event.target);
    });

    $('.char_counted').each(function(index, element){
        updateCharCountedFields(element);
    });

//RESEARCH LOG

    function update_rs_focus_type_fields(event) {
        let option_text = $('.rs-focus-type-selector option:selected').text();
        let qualitative_results_field = $('.rs-qualitative-results');
        let quantitative_results_field = $('.rs-quantitative-results');

        if(option_text === 'Cualitativo'){
            qualitative_results_field.show();
            quantitative_results_field.hide();
        } else if(option_text === 'Cuantitativo'){
            qualitative_results_field.hide();
            quantitative_results_field.show();
        } else if(option_text === 'Mixto'){
            qualitative_results_field.show();
            quantitative_results_field.show();
        } else {
            qualitative_results_field.hide();
            quantitative_results_field.hide();
        }
    }
    $('.rs-focus-type-selector').on('change', update_rs_focus_type_fields);
    update_rs_focus_type_fields();

    function update_rs_variable_fieldset(selector_element){
        let context = $(selector_element).closest('.rs_variable_fieldset');
        let option_text = context.find('.rs_variable_selector option:selected').text();

        //console.log('update even triggered');

        context.find('.rs_subvariable_fields').hide();
        context.find('.rs_performance_variable_fields').hide();
        context.find('.rs_performance_variable_fields').prop('disabled', true);
        context.find('.rs_attention_variable_fields').hide();
        context.find('.rs_attention_variable_fields').prop('disabled', true);
        context.find('.rs_specify_variable_fields').hide();

        if(option_text === 'Conocimiento'){
            context.find('.rs_subvariable_fields').show();
            context.find('.rs_performance_variable_fields').show();
            context.find('.rs_performance_variable_fields').prop('disabled', false);
        }
        else if (option_text === 'Actitud'){
            context.find('.rs_subvariable_fields').show();
            context.find('.rs_attention_variable_fields').show();
            context.find('.rs_attention_variable_fields').prop('disabled', false);
        }
        else if (option_text !== 'Elegir variable'){
            context.find('.rs_specify_variable_fields').show();
        }
    }
    function update_rs_variable_selector_fields(event) {
        update_rs_variable_fieldset(event.target)
    }
    $('.rs_variable_selector').on('change', update_rs_variable_selector_fields);

    function update_all_rs_variable_selectors() {
        $('.rs_variable_selector').each(function (index, element) {
            update_rs_variable_fieldset(element);
        });
    }
    update_all_rs_variable_selectors();

    function update_rs_data_collection_fieldset(selector_element){
        let context = $(selector_element).closest('.rs_variable_fieldset');
        let option_text = context.find('.rs_data_collection_selector option:selected').text();

        if(option_text === 'Otro'){
            context.find('.rs_data_collection_other_fields').show();
        }
        else{
            context.find('.rs_data_collection_other_fields').hide();
        }
    }
    function update_rs_data_collection_fields(event) {
        update_rs_data_collection_fieldset(event.target);
    }

    $('.rs_data_collection_selector').on('change', update_rs_data_collection_fields);
    function update_all_rs_data_collection_selectors() {
        $('.rs_data_collection_selector').each(function (index, element) {
            update_rs_data_collection_fieldset(element);
        });
    }
    update_all_rs_data_collection_selectors();

    function calculateEvaluationSectionBadges(){
        $('.evaluation20-section-title').each((index, value)=>{
            let evaluationSection = $(value).next('.ev-section');
            let criteria20Count = evaluationSection.find('.criteria20-question').length
            let answeredCount = evaluationSection.find('.evaluation20-radio-button:checked').length
            let statusBadge = $(value).find('.evaluation20-section-badge');

            if(answeredCount < criteria20Count){
                statusBadge.removeClass('badge-warning badge-success').addClass('badge-warning');
                statusBadge.text('En proceso');
            }
            else{
                statusBadge.removeClass('badge-warning badge-success').addClass('badge-success');
                statusBadge.text('Compledo');
            }
        });
    }

    $('.evaluation20-radio-button').on('change', (event)=>{
        calculateEvaluationSectionBadges();
    });
    calculateEvaluationSectionBadges();



    // Project
    class Project {
        constructor() {
            this.body = $('body');
            this.charts = [];

            // chartjs default styles
            var draw = Chart.controllers.line.prototype.draw;
            Chart.controllers.line.prototype.draw = function () {
                draw.apply(this, arguments);
                var ctx = this.chart.chart.ctx;
                var _stroke = ctx.stroke;
                ctx.stroke = function () {
                    ctx.save();
                    ctx.shadowColor = 'rgba(0,0,0,0.01)';
                    ctx.shadowBlur = 20;
                    ctx.shadowOffsetX = 0;
                    ctx.shadowOffsetY = 5;
                    _stroke.apply(this, arguments);
                    ctx.restore();
                };
            };

            //default config
            Chart.defaults.global.defaultFontColor = '#8391a2';
            Chart.defaults.scale.gridLines.color = '#8391a2';
            // font
            Chart.defaults.global.defaultFontFamily =
                '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif';
        }

        /**
         * Renders the responsive chart
         * @param {*} element element
         * @param {*} type type of chart e.g. bar
         * @param {*} data data
         * @param {*} options chart options
         */
        respChart(element, type, data, options) {
            // get selector by context
            var ctx = element.get(0).getContext('2d');

            // make chart width fit with its container
            var chart = null;
            switch (type) {
                case 'Line':
                    chart = new Chart(ctx, {
                        type: 'line',
                        data: data,
                        options: options,
                    });
                    break;
                case 'Doughnut':
                    chart = new Chart(ctx, {
                        type: 'doughnut',
                        data: data,
                        options: options,
                    });
                    break;
                case 'Pie':
                    chart = new Chart(ctx, {type: 'pie', data: data, options: options});
                    break;
                case 'Bar':
                    chart = new Chart(ctx, {type: 'bar', data: data, options: options});
                    break;
                case 'Radar':
                    chart = new Chart(ctx, {
                        type: 'radar',
                        data: data,
                        options: options,
                    });
                    break;
                case 'PolarArea':
                    chart = new Chart(ctx, {
                        data: data,
                        type: 'polarArea',
                        options: options,
                    });
                    break;
            }
            return chart;
        }

        /**
         * Initilizes the charts
         */
        initCharts() {
            var charts = [];
            if ($('#line-chart-example').length > 0) {
                var lineChart = {
                    labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                    datasets: [
                        {
                            label: 'Completed Tasks',
                            backgroundColor: 'rgba(10, 207, 151, 0.3)',
                            borderColor: '#0acf97',
                            data: [32, 42, 42, 62, 52, 75, 62],
                        },
                        {
                            label: 'Plan Tasks',
                            fill: true,
                            backgroundColor: 'transparent',
                            borderColor: '#727cf5',
                            borderDash: [5, 5],
                            data: [42, 58, 66, 93, 82, 105, 92],
                        },
                    ],
                };

                var lineOpts = {
                    maintainAspectRatio: false,
                    legend: {
                        display: false,
                    },
                    tooltips: {
                        intersect: false,
                    },
                    hover: {
                        intersect: true,
                    },
                    plugins: {
                        filler: {
                            propagate: false,
                        },
                    },
                    scales: {
                        xAxes: [
                            {
                                reverse: true,
                                gridLines: {
                                    color: 'rgba(0,0,0,0.05)',
                                },
                            },
                        ],
                        yAxes: [
                            {
                                ticks: {
                                    stepSize: 20,
                                },
                                display: true,
                                borderDash: [5, 5],
                                gridLines: {
                                    color: 'rgba(0,0,0,0)',
                                    fontColor: '#fff',
                                },
                            },
                        ],
                    },
                };
                charts.push(
                    this.respChart($('#line-chart-example'), 'Line', lineChart, lineOpts)
                );
            }

            return charts;
        }

        /**
         * Initlizes the project app
         */
        init() {
            // init charts
            var self = this;
            self.charts = this.initCharts();

            // enable resizing matter
            $(window).on('resize', function (e) {
                $.each(self.charts, function (index, chart) {
                    try {
                        chart.destroy();
                    } catch (err) {
                        console.log(err);
                    }
                });
                self.charts = self.initCharts();
            });
        }
    }

// init
    var project = new Project();
    project.init();


// Dashboard
    class ProjectDashboard {
        constructor() {
            this.$body = $('body');
            this.charts = [];

            //default config
            Chart.defaults.global.defaultFontColor = '#8391a2';
            Chart.defaults.scale.gridLines.color = '#8391a2';
            // font
            Chart.defaults.global.defaultFontFamily =
                '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif';
        }

        /**
         * Renders the responsive chart
         * @param {*} element element
         * @param {*} type type of chart e.g. bar
         * @param {*} data data
         * @param {*} options chart options
         */
        respChart(element, type, data, options) {
            // get selector by context
            var ctx = element.get(0).getContext('2d');

            // make chart width fit with its container
            var chart = null;
            switch (type) {
                case 'Line':
                    chart = new Chart(ctx, {
                        type: 'line',
                        data: data,
                        options: options,
                    });
                    break;
                case 'Doughnut':
                    chart = new Chart(ctx, {
                        type: 'doughnut',
                        data: data,
                        options: options,
                    });
                    break;
                case 'Pie':
                    chart = new Chart(ctx, {type: 'pie', data: data, options: options});
                    break;
                case 'Bar':
                    chart = new Chart(ctx, {type: 'bar', data: data, options: options});
                    break;
                case 'Radar':
                    chart = new Chart(ctx, {
                        type: 'radar',
                        data: data,
                        options: options,
                    });
                    break;
                case 'PolarArea':
                    chart = new Chart(ctx, {
                        data: data,
                        type: 'polarArea',
                        options: options,
                    });
                    break;
            }
            return chart;
        }

        /**
         * Initilizes the charts
         */
        initCharts() {
            var charts = [];

            if($('#projects-by-school').length > 0)
            {
                let chartData = JSON.parse($('#projects-by-school').attr('data'));

                let donutChart = {
                    labels: Object.keys(chartData),
                    datasets: [
                        {
                            data: Object.values(chartData),
                            backgroundColor: ['#0acf97', '#727cf5', '#fa5c7c', '#048ba8', '#1b998b', '#f46036', '#7b6d8d'],
                            borderColor: 'transparent',
                            borderWidth: '3',
                        },
                    ],
                };

                let donutOpts = {
                    maintainAspectRatio: false,
                    cutoutPercentage: 80,
                    legend: {
                        display: false,
                    },
                };

                charts.push(
                    this.respChart(
                        $('#projects-by-school-chart'),
                        'Doughnut',
                        donutChart,
                        donutOpts
                    )
                );
            }

            if($('#projects-by-region').length > 0)
            {
                let chartData = JSON.parse($('#projects-by-region').attr('data'));

                let donutChart = {
                    labels: Object.keys(chartData),
                    datasets: [
                        {
                            data: Object.values(chartData),
                            backgroundColor: ['#0acf97', '#727cf5', '#fa5c7c', '#048ba8', '#1b998b', '#f46036', '#7b6d8d'],
                            borderColor: 'transparent',
                            borderWidth: '3',
                        },
                    ],
                };

                let donutOpts = {
                    maintainAspectRatio: false,
                    cutoutPercentage: 80,
                    legend: {
                        display: false,
                    },
                };

                charts.push(
                    this.respChart(
                        $('#projects-by-region-chart'),
                        'Doughnut',
                        donutChart,
                        donutOpts
                    )
                );
            }

            if($('#project-budget-chart').length > 0)
            {
                let chartData = JSON.parse($('#project-budget').attr('data'));
                //console.log(chartData);

                let donutChart = {
                    labels: Object.keys(chartData),
                    datasets: [
                        {
                            data: Object.values(chartData),
                            backgroundColor: ['#0acf97', '#727cf5', '#fa5c7c', '#048ba8', '#1b998b', '#f46036', '#7b6d8d'],
                            borderColor: 'transparent',
                            borderWidth: '3',
                        },
                    ],
                };

                let donutOpts = {
                    maintainAspectRatio: false,
                    cutoutPercentage: 80,
                    legend: {
                        display: false,
                    },
                };

                charts.push(
                    this.respChart(
                        $('#project-budget-chart'),
                        'Doughnut',
                        donutChart,
                        donutOpts
                    )
                );
            }

            if($('#total-budget-chart').length > 0)
            {
                let chartData = JSON.parse($('#total-budget').attr('data'));
                //console.log(chartData);

                let donutChart = {
                    labels: Object.keys(chartData),
                    datasets: [
                        {
                            data: Object.values(chartData),
                            backgroundColor: ['#0acf97', '#727cf5', '#fa5c7c', '#048ba8', '#1b998b', '#f46036', '#7b6d8d'],
                            borderColor: 'transparent',
                            borderWidth: '3',
                        },
                    ],
                };

                let donutOpts = {
                    maintainAspectRatio: false,
                    cutoutPercentage: 80,
                    legend: {
                        display: false,
                    },
                };

                charts.push(
                    this.respChart(
                        $('#total-budget-chart'),
                        'Doughnut',
                        donutChart,
                        donutOpts
                    )
                );
            }


            return charts;
        }

        /**
         * Initlizes the dashboard
         */
        init() {
            var $this = this;

            // init charts
            $this.charts = this.initCharts();

            // enable resizing matter
            $(window).on('resize', function () {
                $.each($this.charts, function (index, chart) {
                    try {
                        chart.destroy();
                    } catch (err) {
                        console.log(err);
                    }
                });
                $this.charts = $this.initCharts();
            });
        }
    }

// init the dashboard
    let ds = new ProjectDashboard();
    ds.init();

    $('#user_select').on('change', function(event) {
        let selected_option_text = $(this).children('option:selected').text();
        if (selected_option_text === 'Invitado Externo') {
            $('#code-form-group').hide();
            $('#school-form-group').hide();
            $('#institution-form-group').hide();
            $('#academic-level-form-group').hide();
            $('#contract-form-group').hide();
        } else {
            $('#code-form-group').show();
            $('#school-form-group').show();
            $('#institution-form-group').show();
            $('#academic-level-form-group').show();
            $('#contract-form-group').show();
        }
    });

    $('.search-professor-button').on('click', professor_search_service);

    function professor_search_service(event)
    {
        let fieldset = $(this).parents('.professor-search-group');
        let email = $(fieldset).find('.email-field').val();
        let id_field = $(fieldset).find('.id-field');

        $.ajax({url: '/professors/search',
            data: {email: email},
            type: 'get',
            success: function(data) {
                if (data.error) {
                    $(fieldset).find('.search-error-area').html('<span class="text-danger">Profesor no encontrado. Favor de verificar la información.</span>')
                }
                else {
                    $(id_field).val(data.professor.id);
                    $(fieldset).find('.search-error-area').html('<span class="text-success">Profesor encontrado. Se asignará al guardar cambios.</span>')
                }
            }});
    }
    var idleTime = 0;
    //Increment the idle time counter every minute.
    var idleInterval = setInterval(timerIncrement, 60000); // 1 minute
    //Zero the idle timer on mouse movement.
    //$('body').mousemove(function (e) {
    //    idleTime = 0;
    //});

    //$('body').keypress(function (e) {
    //    idleTime = 0;
    //});

    //$('body').click(function() {
    //    idleTime = 0;
    //});

    function timerIncrement() {
        
        idleTime = idleTime + 1;
        if (idleTime == 80) { // Aquí será cuando el idlTime llegue a 27 minutos
            $("#mdlSessionExpire").modal('show');
            //  alert("Su sesión terminará en 5 minutos por inactividad.");
            console.log("Aquí 27 min");
        }
        if(idleTime == 85) //Si llega al límite de 30 minutos cierra sesión. Se valida menor a 31 (o sea 30)
        {
            console.log("Sesión de 30 minutos al límite");
            //Controller de session para guardar su sesión y recargar página
            //alert('Sesion expirada');
            $.ajax({
                url: '/session_timeout',
                success: (data) => {
                    if(data.ok) {
                        alert(data.message);
                        clearInterval(idleInterval);
                        window.location = '/users/sign_in';
                    }
                    else if(data.error){
                        $("#mdlSessionExpire").modal('hide');
                        clearInterval(idleInterval);
                        console.log(data.message);
                    }
                }
            });
        }
    }
    $(".btn-close").on('click', function(event)
    {
        idleTime = 0;
        clearInterval(idleInterval);
    });
    //Reports
    /*
    $('.reports-form').on('submit', function(event){
        event.preventDefault();
        this.submit();
        setTimeout(function() {
            $('.general-report-download-button').removeAttr('disabled');
        }, 5000);
    });
    */
});